import axios from 'axios';
import type { UserResponseT } from '@mentimeter/http-clients';
import { fetchRegion, fetchRegionByWorkspaceUrl } from '@mentimeter/region';
import type { VisitorToken } from '@mentimeter/splitio';
import { LocalStorage } from '@mentimeter/storage';
import { MentiError, captureException } from '@mentimeter/errors';
import type { UtmFirst, UtmLast } from '@mentimeter/utm';
import type {
  AuthResponseT,
  UserClientAuthConfigT,
  UserApiClientT,
  SignupFunctionParamsT,
  SocialSignupFunctionParamsT,
  SignupModeInfoT,
  SignupModeInputParamT,
  SocialAuthProvider,
} from '../../types';
import { capitalize } from '../../utils/capitalize';
import { ServerAuthError, ClientAuthError, ErrorCodes } from './errors';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

interface UserCreatePostBase {
  email: string;
  utm_first: UtmFirst | undefined;
  utm_last: UtmLast | undefined;
  registration_page: string;
  visitor_token: VisitorToken;
  invite_token?: string | undefined;
}
interface UserCreatePostPassword extends UserCreatePostBase {
  password: string;
  name: string;
}

interface UserCreatePostSocial extends UserCreatePostBase {
  id: string;
  access_token: string;
  network: SocialAuthProvider;
}

interface UserLoginPost {
  email: string;
  password: string;
  visitor_token: VisitorToken;
}

interface UserAuthPost {
  access_token: string;
  network: SocialAuthProvider;
  visitor_token: VisitorToken;
}

const createUserHttpClient = (clientBaseUrl: string) => {
  const axiosClient = axios.create({
    baseURL: clientBaseUrl,
    headers: defaultHeaders,
  });

  return {
    createUser(
      postData: UserCreatePostPassword | UserCreatePostSocial,
      baseURL: string,
    ) {
      return axiosClient.post<UserResponseT>(
        `/users`,
        postData,
        baseURL ? { baseURL } : {},
      );
    },
    loginUser(postData: UserLoginPost, baseURL: string) {
      return axiosClient.post<UserResponseT>(
        '/users/client-auth',
        postData,
        baseURL ? { baseURL } : {},
      );
    },

    socialAuth(postData: UserAuthPost, baseURL: string) {
      return axiosClient.post(
        `/users/social-auth`,
        postData,
        baseURL ? { baseURL } : {},
      );
    },

    getSignupMethod(email: string, baseURL: string) {
      return axiosClient.post(
        'workspaces/signup-method',
        {
          email,
        },
        baseURL ? { baseURL } : {},
      );
    },
  };
};

export function createUserApiAuthClient({
  baseUrl: defaultBaseUrl,
}: UserClientAuthConfigT): UserApiClientT {
  const clientBaseUrl = defaultBaseUrl ?? 'no-core-set-build-time';
  const userClient = createUserHttpClient(clientBaseUrl);

  const getRegion = async (email: string) => {
    try {
      const data = await fetchRegion(email, clientBaseUrl);
      return data;
    } catch (e) {
      captureException(
        new MentiError('Region request error', {
          cause: e,
          feature: 'platform',
        }),
      );

      throw e;
    }
  };

  const getRegionByWorkspaceUrl = async (workspaceUrl: string) => {
    try {
      return await fetchRegionByWorkspaceUrl(workspaceUrl, clientBaseUrl);
    } catch (e) {
      new MentiError('Workspace Region request error', {
        cause: e,
        feature: 'platform',
      });

      throw e;
    }
  };

  return {
    async signup({
      email,
      password,
      name,
      referral,
      utmFirst,
      utmLast,
      visitorToken,
      workspaceUrl,
      inviteToken,
    }: SignupFunctionParamsT): Promise<AuthResponseT> {
      if (!LocalStorage.isSupported()) {
        throw new ClientAuthError(ErrorCodes.LOCAL_STORAGE, 'signup');
      }

      let userRegion;
      try {
        userRegion = await getRegion(email);
      } catch (err) {
        throw new ServerAuthError(err, 'signup');
      }

      if (userRegion.exists) {
        throw new ClientAuthError(ErrorCodes.EMAIL_NOT_UNIQUE, 'signup');
      }

      let serviceUrls = userRegion.service_urls;

      // Override region to workspace region if workspace url is provided
      if (workspaceUrl) {
        try {
          const workspaceRegion = await getRegionByWorkspaceUrl(workspaceUrl);
          serviceUrls = workspaceRegion.service_urls;
        } catch (err) {
          throw new ServerAuthError(err, 'signup');
        }
      }

      const postData: UserCreatePostPassword = {
        email,
        password,
        name,
        registration_page: referral,
        utm_first: utmFirst,
        utm_last: utmLast,
        visitor_token: visitorToken,
        invite_token: inviteToken,
      };

      try {
        const { data } = await userClient.createUser(
          postData,
          serviceUrls.core,
        );
        return {
          mentiUser: data,
          isNewUser: true,
        };
      } catch (err: any) {
        throw new ServerAuthError(err, 'signup');
      }
    },

    async login(
      email: string,
      password: string,
      visitorToken: VisitorToken,
    ): Promise<AuthResponseT> {
      if (!LocalStorage.isSupported()) {
        throw new ClientAuthError(ErrorCodes.LOCAL_STORAGE, 'login');
      }
      try {
        const region = await getRegion(email);
        const { data } = await userClient.loginUser(
          {
            email,
            password,
            visitor_token: visitorToken,
          },
          region.service_urls.core,
        );
        return {
          mentiUser: data,
          isNewUser: false,
        };
      } catch (err: any) {
        throw new ServerAuthError(err, 'login');
      }
    },

    async authWithSocialUser({
      socialUser,
      network,
      referral,
      utmFirst,
      utmLast,
      visitorToken,
    }: SocialSignupFunctionParamsT): Promise<AuthResponseT> {
      // If email is not granted as permission
      if (!socialUser.email) {
        throw new ClientAuthError(
          ErrorCodes.SOCIAL_EMAIL_PERMISSION_MISSING,
          'social',
        );
      }

      let userRegion;
      try {
        userRegion = await getRegion(socialUser.email);
      } catch (err) {
        throw new ServerAuthError(err, 'social');
      }

      try {
        const { data } = await userClient.socialAuth(
          {
            access_token: socialUser.accessToken,
            network,
            visitor_token: visitorToken,
          },
          userRegion.service_urls.core,
        );
        return { isNewUser: false, mentiUser: data };
      } catch (err: any) {
        // Pass errors other than that a user wasn't found
        if (err.response?.data?.code !== ErrorCodes.NO_USER_FOUND) {
          throw new ServerAuthError(err, 'social');
        }
      }

      // If a user exists in another region we should not sign up
      if (userRegion.exists) {
        throw new ClientAuthError(ErrorCodes.EMAIL_NOT_UNIQUE, 'social');
      }

      // No user was found meaning we should sign up
      try {
        const { data } = await userClient.createUser(
          {
            access_token: socialUser.accessToken,
            email: socialUser.email,
            id: socialUser.id,
            network: socialUser.network,
            utm_first: utmFirst,
            utm_last: utmLast,
            registration_page: referral,
            visitor_token: visitorToken,
          },
          userRegion.service_urls.core,
        );
        return { isNewUser: true, mentiUser: data };
      } catch (err: any) {
        throw new ServerAuthError(err, 'social');
      }
    },

    async checkSignupMode(
      email: SignupModeInputParamT,
    ): Promise<SignupModeInfoT> {
      const userRegion = await getRegion(email);

      try {
        const { data } = await userClient.getSignupMethod(
          email,
          userRegion.service_urls.core,
        );
        return {
          signupMode: data.signup_mode,
          companyName: capitalize(data.name),
          joinUrl: data.join_url,
          id: data.id,
          samlLoginUrl: data.saml_login_url,
        };
      } catch (err) {
        captureException(
          new MentiError('Signup workspace hint', {
            feature: 'managing-organizations',
          }),
        );
        throw err;
      }
    },
  };
}

import * as React from 'react';
import { useTranslation } from '@mentimeter/i18n';
import * as Yup from 'yup';
import {
  TextInput,
  Form,
  Button as SubmitButton,
} from '@mentimeter/ragnar-form';
import type { FormikHelpers } from 'formik';
import type { TrackingPayload } from '@mentimeter/http-clients';
import { TrackingMethods } from '@mentimeter/google-tracking';
import type { SignupModeInfoT } from '../../types';
import {
  createEmailValidation,
  createPasswordValidation,
  createNameValidation,
  USERNAME_MAX_LENGTH,
} from '../helpers/copy';
import { PasswordInput } from './PasswordInput';
import { WorkspaceHintDataHandler } from './workspacehint/WorkspaceDataHandler';
import { EmailInput as EmailInputWithAutoSuggest } from './EmailInput';
import { BaseEmailInput } from './BaseEmailInput';

interface SubmitParamsT {
  email: string;
  password: string;
  name: string;
}

export interface PropsT {
  onSubmit: (arg0: SubmitParamsT, arg1: FormikHelpers<SubmitParamsT>) => void;
  isLoading: boolean;
  disabled: boolean;
  onEmailBlur: React.FocusEventHandler<HTMLInputElement>;
  onEmailChange: React.ChangeEventHandler<HTMLInputElement>;
  signupModeInfo: SignupModeInfoT;
  initialEmail?: string | undefined;
  initialPassword?: string | undefined;
  initialName?: string | undefined;
  emailChangeDisabled: boolean;
  track?: ((payload: TrackingPayload) => void) | undefined;
  showEmailAutoSuggest: boolean | undefined;
  experiments: string | undefined;
}

export const useSignupValidationSchema = () => {
  const { t } = useTranslation('authPackage');

  return Yup.object().shape({
    ...createEmailValidation(t),
    ...createPasswordValidation(t, 8),
    ...createNameValidation(t),
  });
};

const SignUpForm = ({
  onSubmit,
  onEmailBlur,
  onEmailChange,
  signupModeInfo,
  isLoading,
  disabled,
  initialEmail,
  initialPassword,
  initialName,
  emailChangeDisabled,
  track,
  showEmailAutoSuggest,
  experiments,
}: PropsT) => {
  const { t } = useTranslation('authPackage');
  const validationSchema = useSignupValidationSchema();
  const [focused, setFocused] = React.useState(['']);
  const EmailComponent = showEmailAutoSuggest
    ? EmailInputWithAutoSuggest
    : BaseEmailInput;

  const handleFocus = React.useCallback(
    (
      e: React.FocusEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >,
    ) => {
      const data = e.target.name;
      if (focused.some((c) => c === data)) return;
      TrackingMethods.trackEnterpriseForm(data, 'sign_up');
      setFocused([data, ...focused]);
    },
    [focused],
  );

  return (
    <Form
      initialValues={{
        email: initialEmail || '',
        password: initialPassword || '',
        name: initialName || '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      width="100%"
      alignItems="stretch"
    >
      <TextInput
        name="name"
        label={t('form_name_label')}
        onFocus={handleFocus}
        maxLength={USERNAME_MAX_LENGTH}
      />
      <EmailComponent
        onFocus={handleFocus}
        id="mm-auth-signup-email"
        name="email"
        type="email"
        placeholder={
          experiments === 'signup_work_domain_split_enabled'
            ? 'john@company.com'
            : ''
        }
        label={
          experiments === 'signup_work_domain_split_enabled'
            ? 'Your work email'
            : t('form_email_label')
        }
        onBlur={onEmailBlur}
        onChange={onEmailChange}
        disabled={emailChangeDisabled}
        autoComplete="off"
      />
      <WorkspaceHintDataHandler track={track} signupModeInfo={signupModeInfo} />
      <PasswordInput
        onFocus={handleFocus}
        name="password"
        label={t('form_password_label')}
        hintText={t('form_password_hint_signup')}
      />
      <SubmitButton
        variant="primary"
        type="submit"
        state={isLoading ? 'loading' : undefined}
        mb={2}
        mt={3}
        disabled={disabled}
      >
        {t('form_signup_button')}
      </SubmitButton>
    </Form>
  );
};
export default SignUpForm;

import React from 'react';
import type { BoxT } from '../Box/Box';
import { Box } from '../Box/Box';

export const ModalFooter = ({ children, ...props }: BoxT) => (
  <Box
    width="100%"
    flexDirection={['column-reverse', 'row']}
    alignItems={['stretch', 'center']}
    justifyContent="flex-end"
    flexWrap="wrap"
    px="space8"
    pb="space8"
    pt="space1"
    rowGap="space2"
    columnGap="space4"
    {...props}
  >
    {children}
  </Box>
);

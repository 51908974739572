import type { Regions } from '@mentimeter/http-clients';

export function setSessionCookies(sessionToken: string, region: Regions) {
  return fetch('/auth/api/session', {
    body: JSON.stringify({
      sessionToken,
      region,
    }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
}

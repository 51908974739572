import { type TextInputProps } from '@mentimeter/ragnar-form';
import { Box, Text } from '@mentimeter/ragnar-ui';
import React, {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useId,
  type KeyboardEvent,
  type MouseEvent,
  type FocusEvent,
} from 'react';
import { useField } from 'formik';
import { BaseEmailInput } from './BaseEmailInput';

const emails = [
  'gmail.com',
  'hotmail.com',
  'yahoo.com',
  'icloud.com',
  'web.de',
  'gmx.de',
  'outlook.com',
  'googlemail.com',
  't-online.de',
  'hotmail.co.uk',
  'outlook.de',
  'qq.com',
  'gmx.net',
  'mail.ru',
  'yahoo.de',
  'aol.com',
  'hotmail.de',
  'gmx.at',
  'live.com',
  'bluewin.ch',
  'me.com',
  'yahoo.co.uk',
  'libero.it',
];

// the minimal value length after which we start showing auto suggest
const MIN_SUGGEST_THRESHOLD = 0;
// maximum count of results in auto suggest
const RESULTS_COUNT = 5;
// trigger suggestions only after we put at sign
const TRIGGER_ONLY_AFTER_AT_SIGN = true;

interface AutoSuggestOptionProps {
  id: string;
  children: ReactNode;
  selected: boolean;
  onHover: () => void;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
}

interface AutoSuggestProps {
  id: string;
  suggestions: string[];
  selectedIndex: number;
  onSelectIndex: (index: number) => void;
  onSubmit: (value: string) => void;
  showSuggestions: boolean;
}

const getEmailMarkup = (email: string) => {
  const atSignLocation = email.lastIndexOf('@');
  const username = email.slice(0, atSignLocation);
  const domain = email.slice(atSignLocation);

  // &#x200E; is a special RTL mark which won't reverse usernames contained with numbers

  return (
    <>
      <Text as="span" color="textWeaker" fontWeight="semiBold">
        &#x200E;{username}
      </Text>
      <Text as="span" color="black" fontWeight="semiBold">
        {domain}
      </Text>
    </>
  );
};

const AutoSuggestOption = ({
  id,
  children,
  selected,
  onHover,
  onClick,
}: AutoSuggestOptionProps) => (
  <Text
    as="li"
    role="option"
    id={id}
    aria-selected={selected}
    onMouseEnter={onHover}
    onMouseDown={onClick}
    extend={({ theme }) => ({
      width: '100%',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      direction: 'rtl',
      padding: `${theme.space[2]}px ${theme.space[3]}px`,
      transition: `background-color ${theme.durations[1]}s ease, color ${theme.durations[1]}s ease`,
      backgroundColor: selected ? theme.colors.bgStrong : 'inherit',
      borderRadius: `8px`,
    })}
  >
    {children}
  </Text>
);

const AutoSuggest = ({
  id,
  suggestions,
  selectedIndex,
  onSelectIndex,
  onSubmit,
  showSuggestions,
}: AutoSuggestProps) => {
  const onMouseLeave = useCallback(() => {
    // remove hover when mouse leaves autosuggest container
    onSelectIndex(-1);
  }, [onSelectIndex]);

  const handleSelect = useCallback(
    (index: number) => () => {
      onSelectIndex(index);
    },
    [onSelectIndex],
  );

  const handleSubmit = useCallback(
    (value: string) => () => {
      // we use onMouseDown instead onClick since onClick triggers later than onBlur
      // and because of that we simply can't catch user clicking on autosuggest option
      // onMouseDown works a bit worse than onClick but still in usual cases the difference is hard to find
      onSubmit(value);
    },
    [onSubmit],
  );

  if (!showSuggestions) {
    return null;
  }

  return (
    <Box
      extend={() => ({
        width: '100%',
      })}
    >
      <Box
        as="ul"
        role="listbox"
        id={id}
        extend={() => ({
          position: 'absolute',
          left: 0,
          top: 0,
          zIndex: 4,
          width: '100%',
          overflow: 'hidden',
          boxShadow: `0px 0px 0px 0px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 3px 3px 0px rgba(0, 0, 0, 0.03), 0px 7px 4px 0px rgba(0, 0, 0, 0.02)`,
        })}
        onMouseLeave={onMouseLeave}
        borderRadius={2}
        borderWidth={1}
        borderStyle="solid"
        borderColor="border"
        bg="bg"
        p={3}
      >
        {suggestions.map((s, i) => (
          <AutoSuggestOption
            id={`${id}-${i}`}
            key={s}
            selected={selectedIndex === i}
            onHover={handleSelect(i)}
            onClick={handleSubmit(s)}
          >
            {getEmailMarkup(s)}
          </AutoSuggestOption>
        ))}
      </Box>
    </Box>
  );
};

export const EmailInput = (props: TextInputProps) => {
  const autoSuggestId = useId();
  const { name, onBlur, onFocus, inputBoxProps } = props;
  const [field, _, meta] = useField<string>(name);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const { value } = field;

  const suggestions = useMemo<string[]>(() => {
    if (!value || value.length < MIN_SUGGEST_THRESHOLD) {
      // empty field or less than threshold, return empty array
      return [];
    }

    const atSignIndex = value.indexOf('@');

    if (atSignIndex <= 0) {
      if (TRIGGER_ONLY_AFTER_AT_SIGN) {
        // return empty list until we get first at sign
        return [];
      }

      // initial case, user started typing
      return emails.slice(0, RESULTS_COUNT).map((v) => value + '@' + v);
    }

    const username = value.slice(0, atSignIndex);

    // try to predict user input
    return emails
      .map((v) => username + '@' + v)
      .filter((e) => e.startsWith(value) && e !== value)
      .slice(0, RESULTS_COUNT);
  }, [value]);

  const handleSelect = useCallback(
    (value: string) => {
      // set selected value in formik and hide suggestions
      meta.setValue(value);
      setShowSuggestions(false);
    },
    [meta],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (!suggestions.length) {
        return;
      }

      if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (selectedIndex + 1 < suggestions.length) {
          // go to the next suggestion
          setSelectedIndex(selectedIndex + 1);
        } else {
          // start from the beginning of the list
          setSelectedIndex(0);
        }
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (selectedIndex > 0) {
          // go to previous suggestion
          setSelectedIndex(selectedIndex - 1);
        } else {
          // start from the end of the list
          setSelectedIndex(suggestions.length - 1);
        }
      } else if (e.key === 'Enter') {
        const newValue = suggestions[selectedIndex];

        if (!newValue) return;

        e.preventDefault();
        handleSelect(newValue);
      } else if (e.key === 'Escape') {
        // hide suggestions when user presses esc
        setShowSuggestions(false);
      }
    },
    [handleSelect, selectedIndex, suggestions],
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      // hide suggestions when input focus is lost
      setShowSuggestions(false);
      onBlur?.(e);
    },
    [onBlur],
  );

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      // show suggestions only if we have them
      setShowSuggestions(suggestions.length > 0);
      onFocus?.(e);
    },
    [onFocus, suggestions.length],
  );

  useEffect(() => {
    // either show or hide suggestions every time they change
    setShowSuggestions(suggestions.length > 0);
    // reset index if amount of suggestions is changed (to avoid out of bounds issue)
    setSelectedIndex(suggestions.length > 0 ? 0 : -1);
  }, [suggestions.length]);

  return (
    <>
      <BaseEmailInput
        {...props}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        inputBoxProps={{
          ...inputBoxProps,
          role: 'combobox',
          'aria-controls': autoSuggestId,
          'aria-autocomplete': 'list',
          'aria-expanded': showSuggestions,
          'aria-activedescendant': `${autoSuggestId}-${selectedIndex}`,
        }}
      />
      <AutoSuggest
        id={autoSuggestId}
        suggestions={suggestions}
        selectedIndex={selectedIndex}
        showSuggestions={showSuggestions}
        onSelectIndex={setSelectedIndex}
        onSubmit={handleSelect}
      />
    </>
  );
};
